.dropdown {
  width: 100%;
  position: relative;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #B22E40; /* Purple color for text */
  font-weight: bold;
  text-decoration: underline;
}

.dropdown-arrow {
  transition: transform 0.2s ease;
  color: white;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content {
  margin-top: 10px;
  padding: 10px;
  background-color: #1f2129; /* Background color */
  border: 1px solid #444;
  border-radius: 5px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-container {
  margin: 20px;
}
