/* .landing-page {
  background-color: #1f2128;
  width: 100%;
}

.landing-page .overlap-wrapper {
  background-color: #1f2128;
  height: 1130px;
  width: 1680px;
}

.landing-page .overlap {
  height: 1130px;
  left: 33px;
  position: relative;
  width: 1647px;
}

.landing-page .overlap-group {
  height: 1130px;
  left: 45px;
  position: absolute;
  top: 0;
  width: 1602px;
}

.landing-page .DMNICON {
  height: 1061px;
  left: 679px;
  object-fit: cover;
  position: absolute;
  top: -83px;
  width: 1033px;
  opacity: 0.3;
} */

/* .landing-page .comment-button {
  display: flex !important;
  height: 49px !important;
  left: 856px !important;
  position: absolute !important;
  top: 75px !important;
  width: 140px !important;
}

.landing-page .div {
  color: #B22E40;
  font-family: "Poppins-Black", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 9px;
  letter-spacing: -0.2px;
  line-height: 55.8px;
  position: absolute;
  text-shadow: 0px 4px 4px 8px #000000;
  top: 508px;
  width: 962px;
}

.landing-page .p {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 9px;
  letter-spacing: -0.2px;
  line-height: 55.8px;
  position: absolute;
  text-shadow: 0px 4px 4px #00000040;
  top: 664px;
  width: 925px;
}

.landing-page .text-wrapper-2 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 125px;
  font-weight: 700;
  left: 0;
  letter-spacing: -0.62px;
  line-height: 174.4px;
  position: absolute;
  text-shadow: 7px 5px 4px 8px #000000;
  top: 363px;
  white-space: nowrap;
  width: 525px;
}

.landing-page .text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 621px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 195px;
}

.landing-page .text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 873px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 77px;
  white-space: nowrap;
  height: "fit-content";
}

.landing-page .generate-instance {
  box-shadow: 0px 4px 4px #00000040 !important;
  display: flex !important;
  height: 59px !important;
  left: 11px !important;
  position: absolute !important;
  top: 826px !important;
  width: 308px !important;
}

.landing-page .text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 44px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  text-shadow: 0px 4px 4px #00000040;
  top: 834px;
  white-space: nowrap;
  width: 256px;
}

.landing-page .img {
  height: 96px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 40px;
  width: 93px;
}

.landing-page .text-wrapper-6 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 141px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 138px;
}

.landing-page .text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 310px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 160px;
}

.landing-page .text-wrapper-8 {
  color: #ffffff;
  font-family: "Poppins-Black", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 505px;
  letter-spacing: -0.15px;
  line-height: 41.9px;
  position: absolute;
  top: 76px;
  white-space: nowrap;
  width: 160px;
} */

.page-content-container {
  margin-top: 112px;
  font-family: Poppins;
}

.hero-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  align-items: center;
  min-height: calc(100vh - 112px);
  position: relative;
}

.page-content-container::before {
  content: "";
  width: 50vw;
  display: block;
  position: fixed;
  right: 0;
  top: -2px;
  height: 100vh;
  background: url("../../assets/DMNICON_transparent.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.hero-section .content-container {
  width: 60%;
  padding-left: 75px;
  padding-bottom: 130px;
}

.hero-section .content-title {
  font-size: 125px;
  font-weight: 700;
  line-height: 150px;
  letter-spacing: -0.005em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin: 0;
  text-shadow: 7px 5px 4px #000000;
}

.hero-section .content-subtitle {
  font-size: 85px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.005em;
  color: #b22e40;
  margin: 0;
  text-shadow: 0px 4px 4px #000000;
}

.hero-section .content-details {
  color: #ffffff;
  text-shadow: 0px 4px 4px #00000040;
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.005em;
  /* margin-top: 80px; */
}

.hero-section .content-button-container {
  display: flex;
  align-items: center;
  gap: 85px;
  padding-top: 20px;
}

.hero-section .content-button-container .content-button {
  border-radius: 10px;
  background: #b22e40;
  border: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 50px;
  width: 280px;
}

.hero-section .content-button-container-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 85px;
  width: 100%;
  padding-block: 54px 70px;
}

.hero-section .content-button-container-mobile .content-button {
  border-radius: 10px;
  background: #b22e40;
  border: none;
  cursor: pointer;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.005em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 50px;
  width: 280px;
}

.hero-section .hero-image-container {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-section .hero-image-container img {
  width: 100%;
  max-width: 345px;
}

/* product and feature page design */
.product-feature-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  flex-direction: column;
  padding-inline: 120px;
  padding-block: 50px;
}

.feature-title {
  font-family: Poppins;
}

.product-feature-section .product-feature-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 66.97px;
  letter-spacing: -0.005em;
  color: #ffffff;
  text-align: center;
  text-shadow: 7px 5px 4px #000000;
}

.feature-description {
  font-family: Poppins;
}

.product-feature-section .product-feature-description {
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #b22e40;
  text-shadow: 0px 4px 8px #000000;

  font-size: 40px;
  margin-inline: auto;
  margin-top: 40px;
}

.product-feature-section .product-feature-wrapper {
  margin-top: 40px;
}

.product-feature-section .product-feature-wrapper .product-feature {
  margin-top: 40px;
}

.product-feature-section .features-card-container {
  display: flex;
  margin-top: 40px;
}

.product-feature-section .features-card-container .feature-card-wrapper .feature-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-feature-section .features-card-container .feature-card .feature-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.005em;
  color: #ffffff;
}

.product-feature-section .feature-card .feature-body {
  display: flex;
  gap: 50px;
}

.product-feature-section .feature-card .text-container {
  display: flex;
  flex-direction: column;
}

.product-feature-section .feature-card .text-container .feature-description {
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #b22e40;
}

.product-feature-section .feature-card .text-container .feature-button {
  font-family: Poppins;
  background: #b22e40;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;

  width: 280px;
  height: 50px;
  margin-inline: auto;
  margin-top: 20px;
}

/* artLink concierge page design */
.artLink-concierge-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 120px;
  padding-block: 100px;
}

.artLink-concierge-section .artLink-concierge-logo {
  height: 114px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  margin-bottom: 34px;
}

.artLink-concierge-section .artLink-concierge-logo .concierge-img {
  height: 100%;
  width: 100%;
}

.artLink-concierge-section .artLink-concierge-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 66.97px;
  letter-spacing: -0.005em;
  color: #ffffff;
  text-align: center;
  text-shadow: 7px 5px 4px #000000;
}

.artLink-concierge-section .artLink-concierge-description {
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.005em;
  text-align: center;
  color: #b22e40;
  text-shadow: 0px 4px 2px #000000;

  font-size: 40px;
  margin-inline: auto;
  margin-top: 50px;
}

.artLink-concierge-section .concierge-button {
  font-family: Poppins;
  background: #b22e40;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;

  width: 280px;
  height: 50px;
  margin-top: 70px;
}

.power-of-art-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 120px;
  padding-block: 100px;
}

.power-of-art-section .power-of-art-logo {
  height: 114px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  margin-bottom: 34px;
}

.power-of-art-section .power-of-art-logo .concierge-img {
  height: 100%;
  width: 100%;
}

.power-of-art-section .power-of-art-description {
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.005em;
  text-align: center;
  color: #b22e40;
  text-shadow: 0px 4px 2px #000000;

  font-size: 50px;
  margin-inline: auto;
  margin-top: 50px;
}

.Join-Wait-list-section {
  font-family: Poppins;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 120px;
  padding-block: 100px;
}

.Join-Wait-list-section .Join-Wait-list-left {
  width: 55%;
}

.Join-Wait-list-section .Join-Wait-list-left .Join-Wait-list-description {
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.005em;
  color: #ffffff;
  text-shadow: 0px 4px 2px #000000;

  font-size: 40px;
  margin-inline: auto;
  margin-top: 50px;
}

.Join-Wait-list-section .Join-Wait-list-left .Join-Wait-list-button {
  background: #b22e40;
  font-size: 28px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 41.86px;
  letter-spacing: -0.005em;
  text-align: center;
  border-radius: 10px;
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  max-width: 280px;
  width: 100%;
  height: 50px;
  margin-top: 70px;
}

.Join-Wait-list-section .Join-Wait-list-right {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.Join-Wait-list-section .Join-Wait-list-right .art-image {
  max-width: 540px;
}

.Join-Wait-list-section .Join-Wait-list-right .art-image img {
  width: 100%;
}

.Join-Wait-list-section .Join-Wait-list-right .artist-details {
  color: #ffffff;
  text-shadow: 0px 4px 4px #00000040;
  font-size: 30px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.005em;
}

.Join-Wait-list-section .Join-Wait-list-right .artist-details .artist-link {
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}

.footer-section {
  display: flex;
  flex-direction: column;
  width: calc(100% - 240px);
  height: 500px;
  align-items: center;
  padding-inline: 120px;
  padding-block: 100px;
  gap: 70px;
  margin-right: 30px;
}

.logo-image {
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-section .logo-image .logo-img {
  height: 100%;
  width: 100%;
}

.footer-section .text {
  font-family: Poppins;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: -0.005em;
  color: #B22E40;
  text-shadow: 0px 4px 4px #000000;
}

.footer-section .footer-icon {
  display: flex;
  flex-direction: row;
  gap: 69px;
}

.footer-section .footer-icon .icon {
  width: 100px;
  height: 100px;
}

.footer-section .footer-icon .icon img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1800px) {

  .hero-section .content-subtitle {
    font-size: 65px;
  }

  .power-of-art-section .power-of-art-description {
    font-size: 42px;
  }

}

@media screen and (max-width: 1600px) {
  .hero-section .content-title {
    font-size: 100px;
    line-height: 130px;
  }

  .hero-section .content-subtitle {
    font-size: 55px;
  }

  .hero-section .content-details {
    font-size: 30px;
    line-height: 44px;
  }

  .hero-section .content-button-container {
    gap: 65px;
  }

  .Join-Wait-list-section .Join-Wait-list-right .artist-details {
    line-height: 38px;
    font-size: 24px;
  }
}

@media screen and (max-width: 1400px) {
  .product-feature-section .features-card-container .feature-card-wrapper {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .product-feature-section .feature-card .feature-body {
    gap: 30px;
  }
}

@media screen and (max-width: 699px) {
  .hero-section {
    height: auto;
    align-items: start;
    height: 100%;
    flex-direction: column;
  }

  .hero-section::before {
    width: 100vw;
    z-index: 0;
    max-height: 800px;
  }

  .hero-section .content-container {
    margin-top: 20px;
    width: 100%;
    padding-left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding-inline: 40px;
    padding-bottom: 70px;
    max-width: calc(100vw - 80px);
  }


  .hero-section .content-title {
    font-size: 80px;
    text-align: center;
    line-height: 130px;
  }

  .hero-section .content-subtitle {
    font-size: 38px;
    line-height: 40px;
    text-align: center;
  }

  .hero-section .content-details {
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    margin-top: 60px;
  }

  .hero-section .content-container {
    padding-bottom: 30px;

  }

  .hero-section .content-button-container {
    display: none;
  }

  .hero-section .hero-image-container {
    width: 100%;
  }

  .hero-section .content-button-container-mobile {
    display: flex;
  }

  .product-feature-section {
    padding-inline: 40px;
    padding-block: 70px;
  }

  .product-feature-section .feature-card .feature-body {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .product-feature-section .features-card-container {
    row-gap: 50px;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .product-feature-section .features-card-container .feature-card-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box;
  }

  .product-feature-section .features-card-container .feature-card .feature-title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }

  .product-feature-section .product-feature-title {
    font-size: 40px;
    line-height: 50px;
  }

  .product-feature-section .product-feature-description {
    font-weight: 700;
    line-height: 38px;
    font-size: 20px;
    margin-top: 20px;
  }

  .artLink-concierge-section {
    padding-inline: 40px;
    padding-block: 70px;
  }

  .artLink-concierge-section .artLink-concierge-title {
    font-size: 40px;
    line-height: 50px;
  }

  .artLink-concierge-section .artLink-concierge-description {
    font-weight: 700;
    line-height: 38px;
    font-size: 20px;
    margin-top: 20px;
  }

  .power-of-art-section {
    padding-inline: 40px;
    padding-block: 70px;
  }

  .power-of-art-section .power-of-art-description {
    font-weight: 700;
    font-size: 38px;
    margin-top: 20px;
  }

  .Join-Wait-list-section {
    padding-inline: 40px;
    padding-block: 70px;
    flex-direction: column;
    gap: 20px;
  }

  .Join-Wait-list-section .Join-Wait-list-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    order: 2;
  }

  .Join-Wait-list-section .Join-Wait-list-left .Join-Wait-list-description {
    font-weight: 700;
    line-height: 38px;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }

  .Join-Wait-list-section .Join-Wait-list-left .Join-Wait-list-button {
    margin-inline: auto;
  }

  .Join-Wait-list-section .Join-Wait-list-right {
    width: 100%;
  }

  .Join-Wait-list-section .Join-Wait-list-right .artist-details {
    line-height: 38px;
    font-size: 20px;
  }

  .footer-section {
    width: calc(100% - 80px);
    align-items: center;
    gap: 40px;
    padding-inline: 40px;
    padding-block: 100px;
    margin-right: 0px;
  }

  .footer-section .text {
    font-size: 35px;
  }

  .footer-section .footer-icon {
    gap: 40px;
  }

  .footer-section .footer-icon .icon {
    height: 60px;
    width: 60px;
  }
}